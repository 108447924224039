main.ant-layout-content {
  section.ant-layout.ant-layout-has-sider.site-layout-background {
    height: 100%;
  }
}

.ant-layout-sider {
  .logo {
    //border-bottom: 1px solid #dbe2ea;
    text-align: center;
    padding: 15px;
    padding-top: 6px;
    padding-bottom: 6px;

    img {
      max-height: 100%;
      max-width: 100%;
    }

    Button {
      margin-top: 15px;
    }
  }

  .ant-menu-submenu-title {
    span {
      i {
        vertical-align: inherit;
        position: relative;
        top: -1px;
      }
    }

    .ant-menu-submenu-arrow {
      transform: translateY(2px);
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        transform: translateY(0);
      }
    }
  }

  .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0;

    // &:first-child {
    //   margin-top: 8px;
    // }

    &:last-child {
      margin-bottom: 8px;
    }

    a,
    a:visited {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

:root {
  --submenu-selected-light: rgba(110, 223, 123, 0.6);
  --submenu-selected-dark: rgba(45, 177, 171, 0.3);
  --menu-item-selected-light: rgba(110, 223, 123, 0.6);
  --menu-item-selected-dark: rgba(45, 177, 171, 0.3);
}

.light-theme {
  --submenu-selected: var(--submenu-selected-light);
  --menu-item-selected: var(--menu-item-selected-light);
}

.dark-theme {
  --submenu-selected: var(--submenu-selected-dark);
  --menu-item-selected: var(--menu-item-selected-dark);
}


.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light {
  .ant-menu.ant-menu-sub.ant-menu-vertical {
    .ant-menu-item-selected {
      background-color: var(--menu-item-selected) !important;
    }
  }
}

.ant-menu-item,
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
  margin-top: 0;

  & .ant-menu-title-content {
    display: flex;
    height: 100%;

    & > a {
      display: flex;
      align-items: center;
      text-decoration: none;

      &:hover,
      &:active,
      &:visited {
        text-decoration: none;
      }

      .sub-menu-item__text {
        color: #fff;
        display: inline-block;
        margin-top: 2px;
        text-decoration: unset !important;
        height: calc(100% - 2px);
      }
    }
  }
}
.ant-layout-sider .ant-menu-item:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .ant-layout-sider {
    .logo {
      border: none;
      padding: 20px 0;
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  main.ant-layout-content section.ant-layout.ant-layout-has-sider.site-layout-background {
    display: flex;
    flex-direction: column;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: 100%;
    padding: 0px !important;
  }
}

.anticon svg {
  margin-right: 5px;
}

.sidebar-bottom {
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  line-height: 40px;
  // padding: 0 34px 0 24px;
  color: rgba(255, 255, 255, 0.65);
  margin: 20px 0;
  background-color: #2db1ab;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
  }
  a:hover {
    color: white;
  }
}
