.ant-calendar-header {
  a {
    &:hover {
      cursor: pointer;
    }
  }
}

.ant-calendar-prev-year-btn,
.ant-calendar-month-panel-prev-year-btn,
.ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-decade-panel-prev-century-btn {
  &:after {
    content: '‹‹' !important;
  }
}

.ant-calendar-prev-month-btn {
  &:after {
    content: '‹' !important;
  }
}

.ant-calendar-next-month-btn {
  &:after {
    content: '›' !important;
  }
}

.ant-calendar-next-year-btn,
.ant-calendar-month-panel-next-year-btn,
.ant-calendar-year-panel-next-decade-btn,
.ant-calendar-decade-panel-next-century-btn {
  &:after {
    content: '››' !important;
  }
}

.ant-badge-status-text {
  background-color: #2db1ab;
  color: #ffffff;
  border-radius: 7px;
  // font-size: 0.57rem;
  font-size: 0.67rem;
  margin-left: 0;
  padding: 2px 7px;
}

.ant-badge-status-dot {
  display: none;
}

.ant-badge-status-Regularwork + .ant-badge-status-text {
  background-color: #ffffff;
  color: #000000;
}

.ant-badge-status-Holidayleave + .ant-badge-status-text {
  background-color: #4472c4;
  color: #ffffff;
}

.ant-badge-status-Sickleaveupto30days65 + .ant-badge-status-text {
  background-color: #cc99ff;
  color: #ffffff;
}

.ant-badge-status-Sickleavemorethan30days65 + .ant-badge-status-text {
  background-color: #66ffc2;
  color: #000000;
}

.ant-badge-status-Sickleavemorethan30days100 + .ant-badge-status-text {
  background-color: #85adad;
  color: #ffffff;
}

.ant-badge-status-Sickleaveupto30days100 + .ant-badge-status-text {
  background-color: #ffff4b;
  color: #000000;
}

.ant-badge-status-Pregnancyleave + .ant-badge-status-text {
  background-color: #00ff00;
  color: #ffffff;
}

.ant-badge-status-Publicholiday + .ant-badge-status-text {
  background-color: #ffc000;
  color: #000000;
}

.ant-badge-status-Otherleave + .ant-badge-status-text {
  background-color: #d9d9d9;
  color: #000000;
}

.ant-badge-status-Paidleave + .ant-badge-status-text,
.ant-badge-status-PaidleaveW + .ant-badge-status-text,
.ant-badge-status-PaidleaveS + .ant-badge-status-text,
.ant-badge-status-PaidleaveD + .ant-badge-status-text,
.ant-badge-status-PaidleaveO + .ant-badge-status-text {
  background-color: #bdd7ee;
  color: #000000;
}

.ant-badge-status-Non-paidleave + .ant-badge-status-text {
  background-color: #c6e0b4;
  color: #000000;
}

.ant-badge-status-Weekendwork + .ant-badge-status-text,
.ant-badge-status-overTime + .ant-badge-status-text {
  background-color: #ff8566;
  color: #ffffff;
}

.ant-badge-status-Nightwork + .ant-badge-status-text {
  background-color: #007070;
  color: #ffffff;
}
.ant-badge-status-Nightworkonpublicholiday + .ant-badge-status-text {
  background-color: #014242;
  color: #ffffff;
}

.ant-badge-status-Nightworkasregularwork + .ant-badge-status-text {
  background-color: #162e2e;
  color: #ffffff;
}

.ant-badge-status-Workonpublicholiday + .ant-badge-status-text {
  background-color: #ff661a;
  color: #ffffff;
}
.ant-badge-status-Overtimeworkonpublicholiday + .ant-badge-status-text {
  background-color: #c04a0e;
  color: #ffffff;
}

.ant-badge-status-Unemployed + .ant-badge-status-text {
  background-color: #ccff99;
  color: #000000;
}

.weekends {
  color: rgba(0, 0, 0, 0.25);
}

.ant-badge {
  //width: 77%;
  width: 100%;
}

.ant-picker-calendar-date-content {
  height: 78px !important;
}

.ant-picker-cell-disabled
  .ant-picker-cell-inner
  .ant-picker-calendar-date-content
  .events
  .ant-badge
  .ant-badge-status-text {
  color: rgba(0, 0, 0, 0.35);
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: none;
}

.ant-badge-status-empty + .ant-badge-status-text {
  background-color: transparent;
}

.selected-date-cell {
  border: #2c2638 1px dashed;
  padding: 5px;
  margin: 0;
  max-height: fit-content;
  min-height: 78px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: transparent;
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.85);
}

@media screen and (max-width: 1600px) {
  .ant-picker-calendar-date-content {
    height: 88px !important;
  }

  .selected-date-cell {
    min-height: 87px;
  }

  .ant-badge-status-Regularwork + .ant-badge-status-text {
    display: flex;
  }

  .ant-picker-calendar-date-value {
    font-size: 0.63rem;
  }

  .ant-badge-status-Holidayleave + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Sickleaveupto30days65 + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Sickleavemorethan30days65 + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Sickleavemorethan30days100 + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Sickleaveupto30days100 + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Pregnancyleave + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Publicholiday + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Otherleave + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Paidleave + .ant-badge-status-text,
  .ant-badge-status-PaidleaveW + .ant-badge-status-text,
  .ant-badge-status-PaidleaveS + .ant-badge-status-text,
  .ant-badge-status-PaidleaveD + .ant-badge-status-text,
  .ant-badge-status-PaidleaveO + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Non-paidleave + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Weekendwork + .ant-badge-status-text,
  .ant-badge-status-overTime + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Nightwork + .ant-badge-status-text {
    display: flex;
  }
  .ant-badge-status-Nightworkonpublicholiday + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Workonpublicholiday + .ant-badge-status-text {
    display: flex;
  }
  .ant-badge-status-Overtimeworkonpublicholiday + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Unemployed + .ant-badge-status-text {
    display: flex;
  }

  .selected-date-cell {
  }
}

@media screen and (max-width: 1600px) and (max-height: 700px) {
  .ant-picker-calendar-date-content {
    height: 73px !important;
  }

  .selected-date-cell {
    min-height: 72px;
  }

  .ant-badge-status-Regularwork + .ant-badge-status-text {
    display: flex;
  }

  .ant-picker-calendar-date-value {
    font-size: 0.63rem;
  }

  .ant-badge-status-Holidayleave + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Sickleaveupto30days65 + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Sickleavemorethan30days65 + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Sickleavemorethan30days100 + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Sickleaveupto30days100 + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Pregnancyleave + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Publicholiday + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Otherleave + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Paidleave + .ant-badge-status-text,
  .ant-badge-status-PaidleaveW + .ant-badge-status-text,
  .ant-badge-status-PaidleaveS + .ant-badge-status-text,
  .ant-badge-status-PaidleaveD + .ant-badge-status-text,
  .ant-badge-status-PaidleaveO + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Non-paidleave + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Weekendwork + .ant-badge-status-text,
  .ant-badge-status-overTime + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Nightwork + .ant-badge-status-text {
    display: flex;
  }
  .ant-badge-status-Nightworkonpublicholiday + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Workonpublicholiday + .ant-badge-status-text {
    display: flex;
  }
  .ant-badge-status-Overtimeworkonpublicholiday + .ant-badge-status-text {
    display: flex;
  }

  .ant-badge-status-Unemployed + .ant-badge-status-text {
    display: flex;
  }

  .selected-date-cell {
  }
}

.ant-picker-cell {
  border: 1px solid #beface;
}
