button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1.625em -0.375em;
  user-select: none;

  > * {
    margin: 0.375em;
  }

  &.center {
    justify-content: center;
  }

  .r-align,
  &.text-right > * {
    @include media('>=sm') {
      margin-left: auto;
    }
  }
}

.category {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
  button {
    border-radius: 50px;
    margin-right: 10px;
    &:focus {
      background-color: #2db1ab;
      border-color: #2db1ab;
      color: #000000;
    }
    &:hover {
      border-color: #2db1ab;
      color: #000000;
    }
  }
}

.initCategory,
.initCategory:hover {
  background-color: #2db1ab;
  border-color: #2db1ab;
  color: #000000;
}
