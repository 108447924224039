.upload-images {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;

  & .upload-area {
    margin-bottom: 20px;
    
    & .ant-upload.ant-upload-drag {
      background-color: white;
    } 
  }
}