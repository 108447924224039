// Nav menu styles

.nav-sider__wrapper {
  z-index: 6;

  .logo-outer__wrapper {
    .logo-inner__wrapper {
      height: 64px !important;
      background-color: #17242a;
      //background-color: #2db1ab;
      transition: all 0.3s ease-in;

      &.dark-theme {
        //background-color: var(--primary-color);
        background-color: #2db1ab;
      }

      .logo-image__element {
        max-height: 49px;
        // TODO: Uncomment this out if we want spining logo
        // animation: spin 5s linear infinite;
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @keyframes spin {
        from {
          transform: rotateY(0deg);
        }
        to {
          transform: rotateY(360deg);
        }
      }
    }

    .yellow-horizontal-header-line__element {
      position: absolute;
      width: 100vw;
      height: 2px;
      background-color: var(--yellow-color);
      z-index: 10;
      bottom: 1px;
    }
  }

  .green-vertical-line__element {
    position: absolute;
    height: 100vh;
    width: 2px;
    background-color: #2db1ab;
    z-index: 10;
    right: 3px;
    top: 0px;
  }

  .black-vertical-line__element {
    position: absolute;
    height: 100vh;
    width: 2px;
    background-color: #454f54;
    z-index: 10;
    right: 3px;
    top: 0px;
  }
}

// HR Separator

.sidebar-items__wrapper {
  border-bottom: 1px solid var(--yellow-color);
  width: 80px !important;
  padding: 20px 0;

  @media screen and (max-width: 1280px) and (max-height: 668px) {
    overflow-y: auto;
    overflow-x: hidden;
    height: 76vh;
    width: 82px !important;

    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    padding-right: 5px;

    /* For WebKit-based browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.sub-menu__icon {
  margin-right: 10px;
}

// TODO: this might not be a good place for the header icon
.logout__icon {
  margin-left: 3px;
  margin-right: 7px;
}

.ant-dropdown__icon {
  color: #fff;

  &.light {
    color: #000;
  }
}

.hr-logo-separator-temporary__wrapper {
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid var(--yellow-color);
  margin-bottom: 10px;

  .hr-logo-separator__wrapper {
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    .elements-group__wrapper {
      width: 60px;
      padding: 5px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top: 2px solid #2db1ab;
      border-bottom: 2px solid #2db1ab;

      .hr-text__element {
        font-weight: 600;
        font-size: 16px;
        transition: color 0.3s ease-in;
        color: var(--hr-logo-text);
      }

      .vertical-line__element {
        width: 2px;
        height: 18px;
        background-color: var(--yellow-color);
      }

      .horizontal-line__element {
        display: inline-block;
        background-color: var(--yellow-color);
        width: 4px;
        height: 2px;
      }
    }
  }
}
