html {
  min-height: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  background: $bodyColor;
  min-width: 320px;
  height: 100%;

  & > iframe {
    display: none;
  }
}

#__next {
  height: 100%;
}

.ant-layout-sider-dark {
  background: var(--sidebar-background);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  position: fixed;
  z-index: 2;
  height: 100vh;
}

#admin-layout {
  height: 100%;
  overflow: hidden;

  section {
    padding: 20px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &.ant-layout {
      padding: 0;
      overflow: hidden;
    }
  }

  .ant-layout-sider {
    background: #2c2738;
    min-height: 100vh;
    z-index: 150;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ant-layout-content {
    padding: 15px 15px 0;
    overflow-x: hidden;
    overflow-y: auto;

    > *:last-child {
      margin-bottom: 15px;
    }
  }
}

header.ant-layout-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 61px;

  span {
    margin-top: 10px;
  }
}

.jodit-workplace {
  min-height: unset !important;
  height: 300px !important;
}

.admin_header {
  @include media('<=lg') {
    padding-left: 20px !important;
  }
}

header.ant-layout-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 61px;

  @include media('<=lg') {
    flex-direction: row;
    justify-content: center;
    z-index: 2 !important;
    align-items: center;

    > button {
      margin-right: auto;
    }

    .ant-dropdown-link {
      margin-left: auto;
    }
  }

  span {
    margin-top: 10px;
  }
}

header.ant-layout-header {
  background: var(--sidebar-background);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: all 0.3s ease-in;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #17242a;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: all 0.3s ease-in;
}

.ant-menu.ant-menu-light,
.ant-menu-light .ant-menu-sub,
.ant-menu.ant-menu-light .ant-menu-sub {
  //background: #005A77;
  background: #2db1ab;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: all 0.3s ease-in;
}

.content-space {
  padding-top: 68px;
  margin-left: 90px;
}

.questionnaire-card {
  min-width: 600px;
}

@media only screen and (max-width: 992px) {
  .content-space {
    margin-left: 0px;
  }
  .questionnaire-card {
    min-width: unset;
  }
}

// Header styles
.layout__wrapper {
  min-height: 100vh;
}
