@use './forms/index';

label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.log {
  .card-wrapper {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 500px;
    @include media('<md') {
      min-width: 320px;
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      color: #2c2638;
      font-weight: 100;
    }
  }
}
.calculator-left-col .ant-form-item .ant-col-7 {
  max-width: 145.58px !important;
  min-width: 145.58px !important;
}

.calculator-left-col .ant-form-item.full-width-label .ant-col-7 {
  max-width: 180px !important;
  min-width: 180px !important;
}

.label-max-width {
  .ant-col {
    max-width: 145.58px !important;
  }
}

.calculator-left-col div .ant-form-item .ant-col-10 {
  max-width: 145.58px !important;
}

.dashboard {
  height: calc(100% - 3px);
  margin-top: 3px;

  .card-wrapper {
    display: flex;
    height: 100%;
    .ant-card {
      width: 60%;

      @include media('<lg') {
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
}

.companyStyleDashboard {
  height: 100%;
  .companyStyleDashboard .card-wrapper {
    display: flex;
    height: 100%;
    .companyStyleDashboard.companyForm.ant-card {
      width: 100%;

      @include media('<lg') {
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: flex-start;
  }
  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.ant-card {
  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: #ffffff;
    }
  }
}

.invoice-product {
  .ant-form-item-row {
    flex-direction: column !important;
    .ant-form-item-label {
      overflow: visible !important;
      label {
        font-size: 14px !important;
      }
    }
    .ant-form-item-control {
      max-width: 100%;
    }
  }
}

.ant-form-item-control-input-content {
  min-height: 32px;

  & .ant-input {
    min-height: 32px;
  }

  & .ant-input-number {
    min-height: 32px;
  }
}

.wrap-label {
  .ant-form-item-label {
    white-space: pre-line;
  }
}

.invoice-product-delete {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 15px !important;
  max-width: 20px !important;
}

.invoice-comment {
  .ant-form-item-control {
    min-width: 100% !important;
  }
}

:root {
  --ant-card-head-color-light: #ffffff;
  --ant-card-head-bg-light: #2db1ab;
  --ant-card-head-color-dark: #2db1ab;
  --ant-card-head-bg-dark: #17242a;
}

.light-theme {
  --ant-card-head-color: var(--ant-card-head-color-light);
  --ant-card-head-bg: var(--ant-card-head-bg-light);
}

.dark-theme {
  --ant-card-head-color: var(--ant-card-head-color-dark);
  --ant-card-head-bg: var(--ant-card-head-bg-dark);
}

.ant-card-head {
  color: var(--ant-card-head-color);
  background-color: var(--ant-card-head-bg);
  opacity: 0.9;
  text-transform: capitalize;
}

.ant-card-head .ant-card-head-title {
  text-transform: initial;
}

.ant-picker-large {
  padding: 5px 11px 6px;
}

.panel-primary > .panel-heading {
  color: #ffffff;
  background-color: #216c97;
  border-color: #216c97;
}

.panel-heading .panel-title span {
  background-color: #216c97 !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.middleExchangeRate {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 400px) {
  .ant-card-head {
    padding: 12px;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

input,
select {
  font-size: 100% !important;
}
.invoice-detail-lines {
  overflow-x: scroll;
}
.invoice-detail-line {
  display: flex;

  gap: 5px;
  .ant-row {
    flex-direction: column !important;
    .ant-form-item-label {
      min-width: 100%;
    }
    .ant-form-item-control {
      min-width: 100% !important;
    }
  }
  .invoice-detail-line-vat {
    .ant-form-item-label {
      min-width: 70px;
    }
  }
}

.companyForm .ant-input[disabled],
.companyForm .ant-picker-input > input[disabled],
.dataForm .ant-input[disabled],
.dataForm .ant-picker-input > input[disabled] {
  color: #17202a;
}

.circleImage {
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border: 1px solid black;
  border-radius: 50px/50px;
}

.regularwork {
  background-color: #ffffff;
}

.holidayleave {
  background-color: #4472c4;
}

.sickleaveupto30days65 {
  background-color: #cc99ff;
}

.sickleavemorethan30days65 {
  background-color: #66ffc2;
}

.sickleaveupto30days100 {
  background-color: #ffff4b;
}

.sickleavemorethan30days100 {
  background-color: #85adad;
}

.pregnancyleave {
  background-color: #00ff00;
}

.maternityleave {
  background-color: #2db1ab;
}

.publicholiday {
  background-color: #ffc000;
}

.otherleave {
  background-color: #d9d9d9;
}

.paidleave {
  background-color: #bdd7ee;
}

.non-paidleave {
  background-color: #c6e0b4;
}

.weekendwork {
  background-color: #ff8566;
}

.nightwork {
  background-color: #008080;
}

.nightworkasregularwork {
  background-color: #162e2e;
}

.nightworkonpublicholiday {
  background-color: #014242;
}

.workonpublicholiday {
  background-color: #ff661a;
}

.overtimeworkonpublicholiday {
  background-color: #c04a0e;
}

.unemployed {
  background-color: #ccff99;
}

.ant-picker-time-panel-column::after {
  height: 0;
}

.ant-picker-calendar-full.ant-picker-calendar-date-content {
  height: calc(100% - 24px);
  overflow-y: overlay;
}

.ant-picker-cell-disabled {
  pointer-events: initial;
}

.clientField,
.cityField,
.addressField,
.tinField {
  margin-left: 55px;
}

.postalField,
.employeeField {
}

.phoneField,
.mealFIeld {
  margin-left: 15px;
}

.travelExpensesField {
  margin-left: 13px;
}

.zipField {
  margin-left: 17px;
}

.holidayField {
  margin-left: -5px;
}

.type-of-work-select__wrapper {
  .ant-select-selector {

    .ant-select-selection-overflow {
      align-self: start;
    }
  }
}

@media only screen and (max-width: 575px) {
  .clientField,
  .cityField,
  .addressField,
  .typesField,
  .postalField,
  .tinField,
  .employeeField,
  .phoneField,
  .zipField,
  .mealFIeld,
  .holidayField,
  .travelExpensesField {
    margin-left: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

#salaryCalendar {
  padding-bottom: 0;
  .ant-form-item {
    margin-bottom: 0;
  }
}

.salaryInputField {
  margin-bottom: 20px;
  padding: 0 10px;
}

.workDayForm {
  // margin-bottom: 20px !important;
}

.salaryCalendar {
  position: relative;
  // margin-top: -50px;
  width: 100%;

  .ant-picker-calendar-header {
    pointer-events: none;
    position: absolute;
    // top: -85px;
    left: 1100px;
    z-index: 5;
    display: none;
  }
}

.ant-input-suffix {
  color: #b1b1b1;
}

.circleDiv {
  width: 20px;
  height: 20px;
  border: 1px solid #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  border-radius: 50%;
}

#multimarkingText,
#customDayText,
#langText {
  display: none;
  position: absolute;
  margin-top: 30px;
  width: 30%;
  z-index: 10;
  border: 1px solid;
  font-size: 0.9em;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

#bonusText,
#otherIncomeText,
#suspensionText,
#otherExpensesText,
#attachSalaryText,
#attachSalaryTextDrawer,
#notSefInvoice,
#employmentDuration {
  display: none;
  position: absolute;
  margin-top: 10px;
  margin-left: 200px;
  width: 50%;
  z-index: 10;
  border: 1px solid;
  font-size: 0.9em;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

#attachText,
#attachTerminationText {
  display: none;
  position: absolute;
  margin-left: 100px;
  z-index: 10;
  border: 1px solid;
  font-size: 0.9em;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

#langText {
  display: none;
  position: absolute;
  z-index: 10;
  border: 1px solid;
  font-size: 0.9em;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  width: auto;
}

#commentText {
  display: none;
  position: absolute;
  margin-top: 30px;
  margin-left: -200px;
  width: 50%;
  z-index: 10;
  border: 1px solid;
  font-size: 0.9em;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

.attachDescription {
  position: absolute;
  margin-top: 10px;
  left: 300px;
}

.salaryUpload .ant-upload-list-text .ant-upload-list-item {
  position: relative;
  height: 35px;
  width: 90%;
  left: 20px;
  margin-top: -22px;
}

.extendButton:hover,
.extendButton:focus {
  color: #2db1ab;
  border-color: #2db1ab;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  &::-webkit-scrollbar {
    display: none;
  }

  & > ul:not(.selected-date-cell) {
    display: flex;
    flex-direction: column;
    gap: 3px;
    border: 1px dashed transparent;
    padding: 5px;
    box-shadow: inset 0 0 3px transparent;
  }
  & > ul {
    margin-bottom: 0;
  }
}

.timesheetForm {
  // max-height: 105%;
  margin-left: 20px;
  padding-top: 16px;
}

.descriptionIcon {
  margin-left: 3px;
  margin-top: 10px;
  color: #b1b1b1;
  padding: 0 20px;
  position: absolute;
  right: -40px;
}

.commentButton {
  &:hover,
  &:active,
  &:focus {
    color: #2db1ab;
    border-color: #2db1ab;
  }
}

@media screen and (max-width: 1570px) {
  #multimarkingText,
  #customDayText {
    margin-top: 60px;
  }
  #bonusText,
  #otherIncomeText,
  #suspensionText,
  #otherExpensesText,
  #attachSalaryText,
  #notSefInvoice,
  #employmentDuration {
    margin-left: 140px;
  }
  .salaryCalendar .ant-picker-calendar-header {
    left: calc(100% - 110px);
  }
  .attachDescription {
    left: 275px;
  }
}

.langSelector .ant-select-selector {
  height: 38px !important;
  display: flex;
  align-items: center;
}

.sectionTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 1.2em;
}

.noDataText,
.noGuidelineText {
  width: 100%;
  text-align: center;
}

.guideline {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  iframe {
    min-width: 90%;
    aspect-ratio: 16 / 9;
  }
}

.fileEdits {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  a {
    display: flex;
    align-items: center;
    height: 66px;
    margin-bottom: 20px;
  }
}

.ant-upload-list-item {
  margin-top: 0;
  margin-bottom: 20px;
}
// TODO: Possibly only one that should be kept
.salaryFormInputName {
  padding: 5px;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #c0cfca;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;
  background: linear-gradient(to bottom, rgba(236, 242, 220, 1), rgba(236, 242, 220, 0));
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

.salaryFormWorkField2 {
  .ant-form-item-row {
    margin-right: -1%;
  }
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  // width: 157px !important;
  min-height: 110px !important;
  margin: 0 !important;
  padding: 5px !important;
  overflow-y: hidden;
}

@media screen and (max-width: 1600px) {
  .salaryCalendar .ant-picker-calendar-header {
    left: calc(100% - 80px);
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    // width: 157px !important;
    display: flex;
    flex-direction: row-reverse;
    min-height: 100px !important;
    gap: 3px;

    .ant-picker-calendar-date-content {
      flex: 1;
    }
  }
  // this regulates width & height of the calendar cells
}

//@media screen and (max-width: 1505px) {
@media screen and (max-width: 1600px) {
  .multimarking {
    margin-top: 30px;
  }
  .timesheetForm {
    font-size: 0.87rem;
  }
  .workDayForm {
    font-size: 0.87rem;
    // margin-bottom: 10px !important;
    label {
      font-size: 0.87rem;
    }
    .ant-form-item-label {
      span {
        width: 100%;
      }
    }
    .ant-input {
      width: 100%;
      height: 15px;
      font-size: 0.87rem !important;
    }
    .ant-picker-input input::placeholder {
      font-size: 0.87rem !important;
    }
    .ant-picker-input {
      height: 15px;
      span {
        svg {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
  .salaryInputField {
    label {
      font-size: 0.87rem !important;
    }

    .ant-form-item-label {
      span {
        width: 100%;
      }
    }

    .ant-input {
      width: 100%;
      height: 15px;
      font-size: 0.73rem !important;
    }

    .ant-form-item-row {
      .ant-row {
        flex-direction: row !important;
        flex-wrap: nowrap !important;
      }
    }
  }
  // TODO: Important Check this input styling
  // .salaryFormInput {
  //   width: 100%;
  //   height: 15px;
  //   font-size: 0.87rem !important;
  //   .ant-input-suffix {
  //     font-size: 0.87rem !important;
  //   }
  //   .ant-input-suffix-wrapper {
  //     padding: 0 10px 0 0 !important;
  //     font-size: 0.87rem !important;
  //     justify-content: center !important;
  //     margin-bottom: -5px;
  //   }
  // }
  // TODO: Check this input styling
  // .salaryFormInputName {
  //   padding: 2px 2px 2px 4px;
  //   width: 94%;
  //   height: 22px;
  //   margin-bottom: 15px;
  // }
  .attach {
    label {
      font-size: 0.87rem !important;
    }

    .ant-form-item-label {
      span {
        width: 100%;
      }
    }
    .ant-select {
      width: 100%;
      height: 22px;
      &.ant-select-single {
        .ant-select-selector {
          font-size: 0.87rem !important;
          height: 22px;
        }
      }
      .ant-select-selection-placeholder {
        font-size: 0.87rem !important;
        margin-top: -3px;
      }
      .ant-select-item-option {
        font-size: 0.67rem;
      }
    }
  }
  .customDaySalaryForm {
    .ant-checkbox {
      font-size: 0.67rem;
    }
  }
  .commentButton {
    font-size: 0.87rem !important;
  }
  .salaryButton {
    font-size: 0.87rem !important;
  }
  .secondOverTime {
    margin-left: 0px;
  }

  .salaryCalendar .ant-picker-calendar-header {
    left: calc(100% - 155px);
  }
  .timesheetCustom {
    .ant-form-item {
      margin-bottom: 1px;
    }
  }
  .salaryFormWorkField {
    // margin-top: 30px;
    label {
      font-size: 0.87rem !important;
    }

    .ant-form-item-label {
      span {
        width: 100%;
      }
    }
    .ant-tree-select {
      font-size: 0.87rem !important;

      @media screen and (max-width: 1530px) {
        font-size: 0.67rem !important;
      }
    }
    .ant-tree-select-dropdown {
      font-size: 0.87rem !important;

      @media screen and (max-width: 1530px) {
        font-size: 0.67rem !important;
      }
    }
  }
  .salaryFormWorkField2 {
    label {
      font-size: 0.87rem !important;

      @media screen and (max-width: 1530px) {
        font-size: 0.67rem !important;
      }
    }

    .ant-form-item-label {
      span {
        width: 100%;
      }
    }
    .ant-form-item-row {
      margin-right: -1%;
    }
    .ant-tree-select {
      font-size: 0.87rem !important;
    }
    .ant-tree-select-dropdown {
      font-size: 0.87rem !important;
    }
    .ant-time-picker {
      font-size: 0.87rem !important;
    }
  }
}

.type-of-work-tree-node-paid-leave {
  cursor: default;

  .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-open {
    cursor: default !important;
  }
}

.type-of-work-dropdown-span__element {
  font-size: 0.87rem !important;

  @media screen and (max-width: 1530px) {
    font-size: 0.67rem !important;
  }
}

@media screen and (max-width: 1600px) and (max-height: 700px) {
  .salaryCalendar .ant-picker-calendar-header {
    left: calc(100% - 80px);
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    // width: 157px !important;
    display: flex;
    flex-direction: row-reverse;
    min-height: 70px !important;
    gap: 3px;

    .ant-picker-calendar-date-content {
      flex: 1;
    }
  }
}

//@media screen and (max-width: 1410px) {
@media screen and (max-width: 1505px) {
  .salaryCalendar .ant-picker-calendar-header {
    position: initial;
    margin-top: 30px;
    margin-bottom: -60px;
  }
  .timesheetForm {
    // padding-top: 180px;
  }
  .timesheetCustom {
    padding-top: 220px;
  }
}

@media (max-width: 767px) {
  .ant-row {
    justify-content: center;
    .guideline {
      margin-bottom: 30px;
      width: 100%;
      iframe {
        max-width: 90%;
      }
    }
    .fileEdits {
      a {
        margin-top: 0 !important;
        white-space: nowrap;
      }
    }
    .guide {
      width: 400px !important;
    }
  }
}

@media screen and (max-width: 575px) {
  .attachDescription {
    margin-top: 50px;
    left: 175px;
  }
  .videos-icons {
    min-width: unset !important;
  }
}

@media (max-width: 420px) {
  .ant-row {
    .guide {
      width: 90% !important;
    }
  }
}

.employeeAttach {
  margin-left: 4px;
}

.timesheetStyles {
  .terminate-extend__wrapper {
    position: relative;


    .dropdown-space__element {
      border: 1px solid #e3e3e3;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
}

@media screen and (max-height: 945px) {
  //@media screen and (max-height: 820px) {
  .calculator {
    .ant-layout {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .ant-layout-header,
    .ant-layout-footer {
      flex: 0 0 auto;
    }

    .ant-layout-content {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .ant-layout-content,
    .ant-layout-content * {
      max-height: 100%;
    }

    .ant-input {
      font-size: 11px;
    }

    .ant-form-item {
      margin-bottom: 10px;
    }
    .dashboard {
      height: 100%;
    }
    .ant-card-head-title {
      font-size: 14px;
    }
    .ant-form-horizontal {
      overflow-y: hidden;
      max-height: 100%;
    }
    .middleExchangeRate {
      font-size: 14px;
    }
  }

  .timesheetStyles {
    .timesheetForm {
      padding-top: 10px;
    }
    // TODO: Check this input styling
    // .salaryFormInputName {
    //   width: 88%;
    //   height: 3.5%;
    //   margin-left: 17px;
    // }
    // TODO: Check this input styling
    // .salaryFormInput {
    //   .ant-input {
    //     width: 100%;
    //     height: 100%;
    //   }
    //   .ant-input-affix-wrapper {
    //     height: 20px;
    //   }
    // }
    .form-horizontal {
      // margin-top: -30px;
    }
    .panel-body {
      // margin-top: -5px;
    }
    .commentButton {
      height: 30px;
    }
    .salarySubmitButton {
      height: 30px;
    }
    .attach {
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 25px;
      }
      .ant-select-selector {
        height: 22px;
      }
    }
    .descriptionIcon {
      margin-top: 2px;
    }
    // TODO: Check this input styling
    // .salaryFormInput {
    //   .ant-input-suffix {
    //     margin-top: 3px !important;
    //   }
    // }
    .timesheetForm {
      margin-left: 15px;
    }
    .ant-form-item-control-input {
      min-height: 26px;
    }
  }

  .customSuffix .ant-input-suffix {
    font-size: 0.7rem !important;
  }
}

.invoice-product-container {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 0;
  background-color: #fafafa;
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
  max-width: 1600px;
}

.invoice-product-header {
  background-color: #f5f5f5;
  font-weight: bold;
  display: flex;
  border-bottom: 2px solid #d9d9d9;
  font-size: 10px;
}

.invoice-product-container {
  & .ant-form-item,
  & .ant-form-item.invoice-product {
    margin-bottom: 0 !important;
  }
}

.header-column {
  padding: 10px;
  border-right: 1px solid #ccc;
  text-align: center;
  flex: 1; /* Default value */
}

.header-column:nth-child(1) {
  flex: 1; /* Service/Product */
}

.header-column:nth-child(2) {
  flex: 4.5; /* Description */
}

.header-column:nth-child(3) {
  flex: 1; /* Quantity */
}

.header-column:nth-child(4) {
  flex: 1; /* Unit */
}

.header-column:nth-child(5) {
  flex: 2; /* Rate/Price */
}

.header-column:nth-child(6) {
  flex: 1; /* VAT */
}

.header-column:nth-child(7) {
  flex: 1; /* Discount % */
}

.header-column:nth-child(8) {
  flex: 2; /* Gross Price */
}

.header-column:nth-child(9) {
  flex: 2; /* Subtotal */
}

.header-column:nth-child(10) {
  flex: 1; /* Action */
}

.invoice-product-row {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
  align-items: center;
  font-size: 8px;
}

.product-column {
  flex: 1;
}

.invoice-product-row .ant-form-item {
  margin-bottom: 0;
}

.invoice-product-input {
  width: 100%;
}

.invoice-remove-btn {
  background: transparent;
  border: none;
  color: #ff4d4f;
  cursor: pointer;
  padding: 0;
}

.invoice-remove-btn:hover {
  color: #ff7875;
}

.add-new-line-btn {
  margin-block: 8px;
  color: white;
  background-color: #4caf50;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.product-price-input-number .ant-input-number-input {
  text-align: right;
}

.product-price-input-number .ant-input-number-handler-wrap {
  width: 20px;
}

.product-price-input-number input {
  padding-right: 24px;
}

.input-text-right {
  & input {
    text-align: right;
  }
}

.table-wrapper {
  overflow-x: auto;
  width: 100%;
  padding: 4px;
  background-color: white;
}

.table-invoice {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse; 
  border: 1px solid #ddd; // TODO: remove
  background-color: #f2f2f2;

  & tr {
    & * {
      border: 0 !important;
    }
  }

  & .invoice-product {
    &:not(.quantity-count) {
      & .ant-input-number-input {
        & .ant-input-number-handler-wrap {
          display: none !important;
        }
      }

      & .product-price-input-number {
        &:hover {
          & .ant-input-number-handler-wrap {
            display: none !important;
          }
        }
      }

      & .ant-input-number-focused {
        & .ant-input-number-handler-wrap {
          display: none !important;
        }
      }
    }
  }

  & .ant-input-number-input-wrap {
    & .ant-input-number-input {
      padding-inline: 10px !important;
    }
  }

  & .subtotal, 
  & .gross-price {
    line-height: 32px;
    text-align: right;
    margin-bottom: 0;
    background-color: white;
    padding-inline: 5px;
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .invoice-remove-btn {
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      margin-right: 0 !important;
      margin-bottom: 1px;
    }
  }

  & th, td {
    width: 1px;
    padding: 5px;
    text-align: left;
    border: 1px solid #ddd;
  }

  & tr {
    & th, 
    & td {
      &:nth-child(1) {
        width: 1px;
        max-width: 90px;
      }

      &:nth-child(2) {
        width: 200px !important;
      }

      @media (min-width: 1440px) {
        &:nth-child(2) {
          min-width: 300px;
        }
      }

      & .quantity-count {
        width: 50px !important;
        & .ant-input-number {
          width: 100%;
        }

        @media (min-width: 1280px) {
          width: 80px !important;
        }

        @media (min-width: 1440px) {
          width: 100px !important;
        }        
      }

      &:nth-child(4) {
        min-width: 100px;
        max-width: 116px;
      }
      
      &:nth-child(5),
      &:nth-child(8),
      &:nth-child(9) {
        width: 1px;
        min-width: 116px;
        max-width: 200px;
      } 
    }
  }
}

.layout-card {
  & .ant-card-body {
    padding-inline: 12px;
  }

  & .table-invoice {
    & thead {
      font-size: 12px;
      font-weight: 500;
      white-space: nowrap;   

      & tr {
        & th {
          padding-block: 12px;
        }
      }
    }

    & tr {
      & th,
      & td {
        padding-left: 7px;
        padding-right: 0px;
      }
    }

    & tbody {
      & tr {
        border-top: 1px solid #ddd;
      }
    }
  }
}

.invoice-product {
  & input,
  & .ant-select {
    font-size: 12px !important;
    // color: red !important;
  }
}