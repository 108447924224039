.ant-statistic-content-prefix {
  .anticon {
    vertical-align: baseline;
    position: relative;
    top: -0.2ex;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #4f66a0 !important;
    border-color: #4f66a0 !important;
  }
}

.reset-pass {
  .ant-card-body {
    padding: 0 30px !important;
  }
  .ant-card-bordered {
    border: none !important;
  }
}

.ant-select.ant-select-single.ant-select-show-arrow .ant-select-selector .ant-select-selection-item {
  flex: unset !important;
}

/* customCarousel.css */
.carousel-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  cursor: grab;
}

.carousel-container:active {
  cursor: grabbing;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: green;
  font-size: 24px;
  line-height: 1;
  z-index: 1000;
}

.ant-carousel .slick-prev {
  position: absolute;
  top: 10px;
  left: 10px;
  transform: translateY(-50%);
}

.ant-carousel .slick-next {
  position: absolute;
  top: 10px;
  right: 10px;
  transform: translateY(-50%);
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  opacity: 0.8;
}

.ant-carousel .slick-dots li button {
  background: #ff4ef6;
  opacity: 0.4;
  margin-bottom: 100px;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #ff4ef6;
  margin-bottom: 100px;
}

.custom-switch .ant-switch-inner {
  font-size: 16px;
  font-weight: bold;
  color: black;
}



