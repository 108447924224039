.admin_header {
  padding-left: 40px;
  padding-right: 0px;
  margin: 0;
  display: flex;
  align-items: center !important;
  line-height: 40px;
  flex-direction: row !important;
  justify-content: flex-end;
  color: #fff;
  // background-color: var(--primary-color) !important;
  position: fixed;
  width: 100%;
  z-index: 5;
  height: 68px !important;

  .bell-icon {
    // height: 50%;
    height: 25px;
    position: relative;
    align-self: flex-start;
    margin-top: 5px;
    .anticon-bell {
      font-size: 25px !important;
      margin-right: 0 !important;
      top: 0;
      color: white;
    }
  }
  // .bell-icon:before {
  //   position: absolute;
  //   content: '';
  //   background-color: #ff0000;
  //   border-radius: 50%;
  //   opacity: 0.5;
  //   width: 10px;
  //   height: 10px;
  //   top: 30px;
  //   right: 0px;
  //   pointer-events: none;
  // }

  > .anticon {
    color: #fff;
    font-size: 30px;
    outline: none;
    margin-right: 20px;

    @include media('<=960px') {
      font-size: 23px;
    }
  }

  .ant-dropdown-link {
    font-size: 16px;
    color: #fff;
    margin-left: 2rem;
    margin-right: 20px;
    cursor: pointer;

    //margin-right: 30px;

    &:hover {
      text-decoration: none;
    }

    @include media('<=960px') {
      font-size: 12px;
    }

    i {
      vertical-align: baseline;
      margin-left: 6px;
    }

    .user-header__wrapper {
      // color: var(--header-user-txt-color);
      color: #fff;
    }
  }

  .ant-select-arrow {
    top: 38%;
  }

  .ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none;
      }
    }
  }
}

.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
  margin-right: 10px;
  margin-left: 5px;
}

.admin_header {
  line-height: 60px;
}

.ant-layout-header span {
  margin-top: 0 !important;
}

.ant-select-item-option-selected {
  &:not(.ant-select-item-option-disabled) {
    background-color: #ffffff;
    font-weight: 400;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

@media only screen and (max-width: 380px) {
  .admin_header {
    .ant-dropdown-link {
      margin-right: 10px;
    }
  }
}

.logo-desktop {
  position: relative;

  @include media('<=lg') {
    display: none;
  }
}
.logo-mobile {
  display: none;

  @include media('<=lg') {
    display: none;
    width: 100px;
    right: -22px;
    position: relative;
  }
}

.btn-collapse {
  display: none;

  @include media('<=lg') {
    display: block;
    z-index: 1000;
  }
}

.nav-menu-collapsed {
  @include media('<=lg') {
    display: block;
    flex: 0 0 66% !important;
    max-width: 66% !important;
    min-width: 66% !important;
    width: 66% !important;
    z-index: 1000;
    margin-top: 61px;
  }
}

.nav-display {
  display: block;
  flex: 0 0 83px !important;
  max-width: 83px !important;
  min-width: 83px !important;
  width: 83px !important;

  @include media('<=lg') {
    display: none;
  }
}

:root {
  --submenu-selected-light: rgba(110, 223, 123, 0.6);
  --submenu-selected-dark: rgba(45, 177, 171, 0.3);
}

.light-theme {
  --submenu-selected: var(--submenu-selected-light);
}

.dark-theme {
  --submenu-selected: var(--submenu-selected-dark);
}

.menu-item__wrapper,
.ant-menu-item.ant-menu-item-active {
  color: #fff;
  
  &:hover {
    //background-color: rgba(45, 177, 171, 0.1);
    background-color: var(--submenu-selected) !important;
  }
  .ant-menu-submenu-title {
    padding: 0 calc(50% - 13px) !important;
  }
}

.menu-item__wrapper {
  &.ant-menu-submenu-selected {
    //background-color: rgba(45, 177, 171, 0.1) !important;
    //background-color: rgb(45, 177, 221) !important;
    //background-color: rgb(110, 223, 123, 0.9) !important;
    background-color: var(--submenu-selected) !important;
  }
}
