  // TODO: Analyze and import styles from _forms.scss that contain salary configuration files
  // Salary.js
.salary__wrapper {
  padding: 8px;

  .employ-company-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 0 10px;

    .salary-company-picker.panel-body {
      padding: 1em;
      padding-left: 5px;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;

      .ant-form-item {
        margin-bottom: 0;
      }

      .select-dropwdown {
        min-width: 225px;
        max-width: 325px;
      }

      // TODO: below class might not be used
      .ant-tabs-tab {
        padding: 8px !important;
      }
    }

    // SalaryFormDatePicker.js styles
    .salary-form-date-picker__wrapper {
      margin-top: 0px;
      align-self: center;

      .select__year {
        width: 45%;
      }

      .select_month {
        width: 45%;
        margin-left: 10px;
      }
    }

    .docs-settings__form-item-wrapper {
      padding: 16px 0px 16px 0px !important;
      margin-bottom: 0 !important;
      align-self: center !important;

      .checkbox-wrapper {
        .wrapper {
          display: flex;
        }
      }
    }
  }

  // SalaryTable.js
  .salary-table_element {
    width: 100%;
  }

  // SalaryFormDowloadSection.js
  .salary-form-download-section__wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  // SalaryForm.js
  .salary-form__wrapper {
    padding-bottom: 0;

    .salary-form__inner-wrapper {
      padding: 15px;
      padding-top: 0;
    }

    .salary-form__form-wrapper {
      // There is rest of the form elements above

      .salary-form-row__wrapper {
        display: flex;
        padding: 0px;

        .calendar-upper-header__wrapper {
          display: grid;
          grid-template-columns: max-content max-content 1fr;
          gap: 10px;
          align-items: center;
          margin-bottom: 10px;

          @media screen and (max-width: 1255px) {
              grid-template-columns: max-content max-content min-content;
              gap: 5px;
            }

          .top-section-name__wrapper {
            display: flex;
            gap: 5px;

            .full-name__wrapper {
              display: flex;
              align-items: start;
              flex-direction: column;
              background-color: #e3e3e3;
              padding: 5px 10px;
              gap: 10px;
              max-height: 91px;
              width: 230px;
              min-width: 230px;
              // background-color: #30bdb7;
              color: #000;
              font-weight: 600;
              font-size: 14px;

              .full-name__element {
                text-overflow: ellipsis;
                width: 160px;
                text-wrap: nowrap;
                overflow: hidden;
              }

              .holidays__section {
                display: block;
                font-weight: 400;
                font-style: italic;
              }
            }

            .hidden-input__wrapper {
              opacity: 0;
              height: 0;
              width: 0;

              .input__element {
                background: #fff;
                border: unset;
                font-style: italic;
                color: #5b5b5b;
                padding-top: 4px;

                @media screen and (max-width: 1600px) {
                  padding: 0;
                }
              }
            }
          }

          .calendar-configuration__wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 20px;
            align-items: baseline;

            @media screen and (max-width: 1440px) {
              gap: 10px;
            }

            @media screen and (max-width: 1340px) {
              gap: 5px;
            }
          }

          .calendar-header-buttons__wrapper {
            display: flex;
            flex-direction: row;
            align-self: start;
            justify-content: end;
            gap: 5px;

            @media screen and (max-width: 1330px) {
              flex-direction: column;
              align-items: end;
            }

            @media screen and (max-width: 1240px) {
              flex-direction: initial;
              align-items: initial;
            }

            .calendar-header-btn__element {
              height: unset;
              padding: 8px 20px;

              &.calendar-header-menu__element {
                padding: 8px 14px;
              }

              & > span {
                font-size: 16px;
                font-weight: 400;
              }
            }

            .btn-menu__wrapper {
              position: relative;
              display: flex;

              .btn-menu-helper__element {
                position: absolute;
                top: 45px;
                font-size: 15px;
                left: 3px;
              }
            }
          }

          @media screen and (max-width: 1240px) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            .top-section-name__wrapper,
            .calendar-header-buttons__wrapper {
              grid-row-start: 0;
              grid-row-end: 1;
            }

            .top-section-name__wrapper {
              grid-row-start: 1;
              grid-row-end: 2;
              grid-column: 1/2;
            }

            .calendar-header-buttons__wrapper {
              grid-row-start: 1;
              grid-row-end: 2;
              grid-column: 3/4;
            }

            .calendar-configuration__wrapper {
              grid-row-start: 2;
              grid-row-end: 3;
              grid-column: 1 / 4;
            }
          }
        }
      }
    }
  }
}

// Drawer is rendered outside of root so it needs to be separated
.employee-salary-drawer__wrapper {
  padding: 0px 20px 20px 10px;
  display: flex;
  flex-direction: column;

  .form-items__wrapper {
    display: block;

    .items-scrollable__wrapper {
      height: calc(100vh - 135px);
      overflow-y: auto;
      scrollbar-width: thin; /* "auto" or "thin" depending on browser support */
      scrollbar-color: #888 #f1f1f1; /* thumb and track colors */
      padding-right: 5px;

      /* For WebKit-based browsers (Chrome, Safari) */
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .ant-form-item-label > label {
        height: 20px !important;
      }

      @media screen and (max-height: 773px) {
        height: calc(100vh - 120px);

        .ant-form-item-control-input {
          position: relative;
          display: flex;
          align-items: center;
          min-height: 24px;
        }
      }
    }

    .salaryInputField {
      margin-bottom: 20px;
      padding: 0;
      .ant-input-affix-wrapper {
        align-items: center !important;

        @media screen and (max-height: 773px) {
          padding: 0px 11px;
        }
      }

      .ant-row {
        align-items: center !important;
      }
    }

    .upload-field__wrapper {
      & > div {
        display: flex;
        flex-direction: column;
      }
      .upload-select__element {
        height: unset;
        width: 50%;

        .ant-select-selector {
          height: unset;

          .ant-select-selection-placeholder {
            margin-top: unset !important;
          }
        }
      }
      .upload-elements__wrapper {
        display: flex;
        gap: 10px;

        .question-circle-icon {
          margin-left: 3px;
          color: #b1b1b1;
        }
      }
    }

    .file-upload-collapse__wrapper {
      .ant-collapse-content.ant-collapse-content-active,
      .ant-collapse-content.ant-collapse-content-inactive {
        max-height: 137px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #888 #f1f1f1;
        padding-right: 5px;

        /* For WebKit-based browsers (Chrome, Safari) */
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .salaryUpload {
          .ant-upload.ant-upload-select.ant-upload-select-text {
            position: absolute;
          }

          .ant-upload-list.ant-upload-list-text {
            .ant-upload-list-text-container {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }

              .file-list-item__wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 5px;

                .file-icon-img__element {
                  height: 22px;
                  width: 22px;
                  margin-right: 5px;
                }

                .file-name__element {
                  width: 100%;
                  font-size: 12px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .file-actions__wrapper {
                  display: flex;
                  flex-direction: row;
                  gap: 8px;

                  // TODO: icon-span-fix goes here
                  .icon-span-fix {
                    svg {
                      margin-right: 0 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .submit-button-group__wrapper {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    padding: 15px 45px 30px;
    background-color: #fff;
    gap: 16px;
    width: 520px;

    .comment-button__wrapper {
      text-align: center;
      display: flex;
      gap: 10px;

      .comment-outlined-icon__element {
        font-size: 20px;
      }
    }

    .submit-button__wrapper {
    }
  }
}

.calendar-header-checkboxes__wrapper {
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;

  @media screen and (max-width: 1400px) {
    gap: 0px;
    grid-row-gap: 8px;
  }
  
  div:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
  div:nth-child(2) { grid-area: 2 / 1 / 3 / 2; }
  div:nth-child(3) { grid-area: 1 / 2 / 2 / 3; }
  div:nth-child(4) { grid-area: 2 / 2 / 3 / 3; }

  .customDaySalaryForm {
    .ant-form-item-control-input {
      min-height: unset !important;
      height: 30px;
    }
  }
}

.calendar-form-item__wrapper {
  &.calendar-type-of-work__wrapper {
    width: 310px;
  }

  // TODO: This is temporary
  @media screen and (max-width: 1530px) {
    &.calendar-type-of-work__wrapper {
      width: 250px;
    }
  }

  @media screen and (max-width: 1320px) {
    &.calendar-type-of-work__wrapper {
      width: 250px;
    }
  }

  .ant-select-selector,
  .ant-picker-input {
    width: 100% !important;
    height: 32px !important;
    padding: 0 11px !important;
  }

  .ant-select-selector {
    .ant-select-selection-item {
      display: flex;
      align-self: center;
    }
  }

  .time-picker__element {
    padding: 0;
    width: 128px;

    @media screen and (max-width: 1400px) {
      width: 90px;
    }



    .ant-picker-input {
      width: 100% !important;
      height: 30px !important;
      padding: 0 11px !important;
    }
  }

  &.display-none {
    display: none;
  }

  &.display-block {
    display: block;
  }

  .ant-row.ant-form-item-row {
    flex-direction: column;
    .ant-col.ant-form-item-label {
      text-align: start;
    }
  }
}
