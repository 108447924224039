.official-travel {
  padding-top: 50px;

  & .step-form {
    width: 100%;
    max-width: 1200px;

    & > div {
      padding: 20px;
      background-color: #f5f5f5;
    }

    & h3 {
      margin-bottom: 0;
    }
  }

  & .step-language {
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 15px;
    background-color: #f5f5f5;
    margin-bottom: 7px;
    min-height: 52px;

    & .user-info {
      margin-bottom: 0;
    }

    & .ant-form-item {
      margin-bottom: 0 !important;
    }

    & .and-select {
      background-color: red !important;
    }
  }

  & .step-navbar {
    position: relative;
    margin-bottom: 30px;

    @media (min-width: 997px) {
      padding-left: 26px;
    }
  
    & .navbar-items {
      display: flex;
      margin-bottom: 0 !important;
      @media (max-width: 996.98px) {
        justify-content: center;
      }
  
      & .navbar-item {
        position: relative;
        display: grid;
        width: max-content;
        cursor: pointer;
  
        &.active {
          & .item-label {
            background-color: var(--yellow-color);
          }
  
          & .item-text {
            color: black;
          }
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed !important;

          & > button {
            cursor: not-allowed !important;
          }
        }
  
        // BUTTON 
        & .item-label {
          width: 32px;
          height: 32px;
          margin-inline: auto;
          background-color: #ccc;
          border-radius: 100%;
          font-weight: 600;
        }
  
        // UNDERLINE TEXT
        & .item-text {
          position: absolute;
          top: -15px;
          left: 50%;
          transform: translate(-50%, -50%);
          width: max-content;
        }
      }
  
      & .navbar-line {
        width: 140px;
        height: 4px;
        border-radius: 5px;
        background-color: #ccc;
        margin-top: 14px;
      }

      @media (min-width: 997px) {
        & .navbar-line {
          width: 180px;
        }
      }

      @media (min-width: 1200px) {
        & .navbar-line {
          width: 220px;
        }
      }

      @media (min-width: 1400px) {
        & .navbar-line {
          width: 260px;
        }
      }
    }
  }

  & .step-control {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    border-radius: 5px;
  
    & button {
      display: flex;
      gap: 5px;
      justify-items: center;
      align-items: center;
      padding: 10px;
      border: 1px solid transparent;
      transition: all 0.2s ease;

      &:first-child {
        & span {
          margin-right: 5px;
        }
      }

      &:last-child {
        & span {
          margin-left: 5px;
        }
      }

      &:hover {
        color: var(--ant-card-head-color);
        border-color: var(--ant-card-head-color);
      }
    }
  }

  & .step-header {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;

    & .block-title {
      font-size: 28px;
    }
  }

  & .table-actions {
    & .action-button {
      width: 42px;
      height: 42px;
      background-color: white;
      border-radius: 3px;
      box-shadow: 0 2px 4px rgba(0,0,0,.1);

      & .anticon svg {
        margin-right: 0;
      }
    }
  }

  // TODO: Travel order
  & .travel-order {
    & .travel-order-form {
      padding: 30px 10px;
      background-color: white;
      border: 1px solid #ccc;

      & .input-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
      }

      & .ant-form-item {
        margin-bottom: 10px !important;
      }
    }
  }

  // TODO: Finish Travel Order
  & .finish-travel-order {
    .article-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    & .article {
      display: grid;
      gap: 15px;

      & > .section {
        padding: 10px;
        background-color: white;
        border: 1px solid #ccc;

        & .header {
          position: sticky;
          top: 68px;
          left: 0; 
          right: 0;
          background-color: white;
          z-index: 1;
        }

        & .title {
          font-size: 20px;
          margin-bottom: 10px;
          border-bottom: 1px solid #ccc;
          padding: 10px;

          & .btn-link {
            font-weight: 600;
            font-style: italic;
            color: var(--ant-card-head-color);
          }
        }

        & .details {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;

          & > div {
            flex: 1 1 0;

            & > div {
              display: flex;
              gap: 10px;
              justify-content: space-between;
              padding: 15px;
              transition: background-color 0.15s ease-in;
              will-change: background-color;

              &:hover {
                background-color: #f5f5f5;
                border-radius: 5px;
              }

              & .label {
                margin-bottom: 0;
              }
            }
          }

    
        }       
        
        & .table-details {
          & .table-list {
            display: grid;
            grid-gap: 20px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            margin-bottom: 10px;

            & .table-header  {
              font-size: 20px;
              margin-bottom: 10px;
              border-bottom: 1px solid #ccc;
              padding: 10px;
            }

            & .mark-row {
              background-color: #f0f6f8; // var(--yellow-color);
            }
          }
        }
      }
    }
  }

  // TODO: Travel Calculation
  & .travel-calculation {
    & .form-wrapper {
      margin-bottom: 24px;

      .form-travel-calculation {
        display: grid;
        grid-gap: 30px 20px;
        grid-template-columns: repeat(1, 1fr);
        padding: 20px 10px;
        border: 1px solid #ccc;
        background-color: white;
        border-radius: 3px;

        @media (min-width: 1200px) {
          grid-template-columns: repeat(2, 1fr);
        }

        & .group {
          & .ant-form-item {
            margin-bottom: 0;
          }

          & .ant-space {
            width: 100%;

            & .ant-space-item {
              & > div {

                width: 100%;
              }
            }
          }
        }

        & .actions {
          @media (min-width: 1200px) {
            grid-column: 2 / 2;
          }
          
          & .ant-form-item {
            margin-bottom: 10px !important;
          }

          & button {
            width: 100%;
          }
        }
      }
    }

    & .table-wrapper {
      padding: 10px;
      border: 1px solid #ccc;
      background-color: white;
    }
  }

  // TODO: Travel Invoices
  & .travel-invoices {
    & .table-invoices-wrapper {
      display: grid;
      grid-gap: 24px;

      & .wrapper {
        border: 1px solid #ccc;

        & .ant-table-wrapper {
          padding: 15px 10px;
          background-color: #fff;
        }
      }

      & .table-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        grid-gap: 15px;
        align-items: center;
        padding-block: 10px;
        background-color: #fff;
        padding-inline: 10px;
        border-bottom: 1px solid #ccc;

        & h2 {
          margin-bottom: 0;
        }
      
        & .btn-toggle-upload {
          background-color: white;
          padding: 8px 15px;
          border: 1px solid #ccc;
        }
      }
    }
  }

  & .sticky-actions {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 20px;
    background-color: rgba(255,255,255,0.75);
    backdrop-filter: blur(6px);
    border: 1px solid #ccc;
    margin-top: 20px;
    z-index: 31;

    & .grid {
      display: grid;
      justify-content: flex-end;
      padding: 12px 20px;
    }

    & .flex {
      display: flex;
      grid-gap: 15px;
      justify-content: space-between;
    }

    & .link {
      padding-inline: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .travel-calculation,
  & .travel-invoices, 
  & .finish-travel-order {
    & .sticky-actions {
      opacity: 100;
    }
  }

  & .image-lists {
    padding: 15px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    display: grid; 
    grid-gap: 10px;

    & .list-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      padding: 7px 10px;

      & .text {
        margin-bottom: 0 !important;
      }

      & .right {
        display: flex;
        gap: 10px;
      }
      
      & .btn {
        width: 32px;
        height: 32px;
        border-radius: 3px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        background-color: white;
        
        & svg {
          margin-right: 0 !important;
        }
      }
    }
  }
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #dcdcdc;
}

.ant-table-wrapper {
  & .input-wrapper {
    & .ant-input.ant-input-disabled {
      color: green !important;
      text-align: right;
      padding-right: 10px;
    }

    &.highlighted {
      & .ant-input.ant-input-disabled {
        font-weight: bold;
      }
    }
  }
}