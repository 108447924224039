.ant-btn {
  text-transform: uppercase;
  height: 38px;
  letter-spacing: 0.05em;
  font-size: 13px;
  border-radius: 2px;

  .anticon {
    vertical-align: text-top;
  }
}

:root {
  --primary-color-light: #2db1ab;
  --primary-color-light-hover: #36c4b3;
  --primary-color-dark: #17242a;
  --primary-color-dark-hover: #1f2e34;
  --text-color-light: #ffffff;
  --text-color-dark: #2db1ab;
}

.light-theme {
  --primary-color: var(--primary-color-light);
  --primary-color-hover: var(--primary-color-light-hover);
  --text-color: var(--text-color-light);
}

.dark-theme {
  --primary-color: var(--primary-color-dark);
  --primary-color-hover: var(--primary-color-dark-hover);
  --text-color: var(--text-color-dark);
}

.ant-btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--text-color);

  &:hover,
  &:focus {
    background-color: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
  }
}


.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: #4f66a0;
  background: #fff;
  border-color: #4f66a0;
}

.ant-btn-danger {
  background-color: $danger;
  color: #fff;
  border-color: transparent;

  &:focus {
    color: #fff;
    background-color: darken($danger, 5);
    border-color: transparent;
  }
}

.ant-picker-footer {
  display: flex !important;
  justify-content: center !important;
}
