.theme-switch__wrapper {
  position: absolute;
  bottom: 2em;
  left: 20px;

  .toggle {
    visibility: hidden;
  }

  .toggle + label {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    cursor: pointer;
    color: var(--primary-switch-circle);

    &::before {
      content: '';
      height: 20px;
      width: 40px;
      border-radius: 1em;
      background: var(--primary-switch-background);
      margin-right: 0;
      transition: all 250ms ease-in-out;
    }

    &::after {
      content: '';
      height: 0.8em;
      width: 0.8em;
      border-radius: 1em;
      background: var(--primary-switch-circle);
      position: absolute;
      left: 0;
      transition:
        all 250ms ease-in-out,
        transform 250ms ease-in-out;
    }
  }
  .toggle:checked + label::after {
    transform: translateX(100%);
  }
}
